import { http } from '@/api/Connect';
import { BASE_GAME_CENTER_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  IWheelOfFortuneCampaignListParams,
  IWheelOfFortuneCampaignListResponse,
  IWheelOfFortuneCampaignDetails,
  IWheelOfFortuneCampaignCreatePayload,
  EWheelOfFortuneCampaignStatusType,
  IWheelOfFortuneCampaignListSearchParams
} from '@/api/schema';

const API_WHEEL_OF_FORTUNE = {
  CAMPAIGNS_LIST(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels`; //GET
  },
  CAMPAIGNS_LIST_SEARCH(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels/search`; //GET
  },
  CAMPAIGN_DETAILS(id: string | number): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels/${id}`; //GET
  },
  CREATE_CAMPAIGN(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels`; //POST
  },
  UPDATE_CAMPAIGN(id: string | number): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels/${id}`; //PATCH
  },
  UPDATE_CAMPAIGN_STATUS(id: string | number): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels/${id}/status`; //PATCH
  },
  DELETE_CAMPAIGN_EVENTS(id: string | number): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/fortune-wheels/${id}`; //DELETE
  }
};

export async function getCampaignsList(
  params: IWheelOfFortuneCampaignListParams
): Promise<IWheelOfFortuneCampaignListResponse> {
  return http
    .get(API_WHEEL_OF_FORTUNE.CAMPAIGNS_LIST(), { params, cacheTime: 0 })
    .then(
      ({ data }: AxiosResponse<IWheelOfFortuneCampaignListResponse>) => data
    );
}

export async function getCampaignsListSearch(
  params: IWheelOfFortuneCampaignListSearchParams
): Promise<IWheelOfFortuneCampaignListResponse> {
  return http
    .get(API_WHEEL_OF_FORTUNE.CAMPAIGNS_LIST_SEARCH(), {
      params,
      cacheTime: 0,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(
      ({ data }: AxiosResponse<IWheelOfFortuneCampaignListResponse>) => data
    );
}

export async function getCampaignDetails(
  campaignId: number | string
): Promise<IWheelOfFortuneCampaignDetails> {
  return http
    .get(API_WHEEL_OF_FORTUNE.CAMPAIGN_DETAILS(campaignId), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<IWheelOfFortuneCampaignDetails>) => data);
}

export async function createCampaign(
  payload: IWheelOfFortuneCampaignCreatePayload
): Promise<IWheelOfFortuneCampaignDetails> {
  return http
    .post(API_WHEEL_OF_FORTUNE.CREATE_CAMPAIGN(), payload)
    .then(({ data }: AxiosResponse<IWheelOfFortuneCampaignDetails>) => data);
}

export async function updateCampaign(
  campaignId: number | string,
  payload: IWheelOfFortuneCampaignCreatePayload
): Promise<IWheelOfFortuneCampaignDetails> {
  return http
    .patch(API_WHEEL_OF_FORTUNE.UPDATE_CAMPAIGN(campaignId), payload)
    .then(({ data }: AxiosResponse<IWheelOfFortuneCampaignDetails>) => data);
}

export async function updateCampaignStatus(
  campaignId: number | string,
  status: EWheelOfFortuneCampaignStatusType
): Promise<IWheelOfFortuneCampaignDetails> {
  return http
    .patch(API_WHEEL_OF_FORTUNE.UPDATE_CAMPAIGN_STATUS(campaignId), null, {
      params: { status }
    })
    .then(({ data }: AxiosResponse<IWheelOfFortuneCampaignDetails>) => data);
}

export async function deleteCampaignEvents(
  campaignId: number | string,
  eventIds: string[] | number[]
): Promise<void> {
  return http.delete(API_WHEEL_OF_FORTUNE.DELETE_CAMPAIGN_EVENTS(campaignId), {
    params: eventIds
  });
}
