


























































import { mapActions, mapGetters } from 'vuex';
import { updateStateFeatureFlag } from '@/api/CopyStake/FeatureFlags';
import { updateCampaignStatus } from '@/api/CopyStake/GamificationCenter/WheelOfFortune';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  EWheelOfFortuneCampaignStatusType,
  IWheelOfFortuneCampaign,
  IWheelOfFortuneCampaignDetails
} from '@/api/schema';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  name: 'CopyStakeGamificationCenterWheelOfFortuneTab',
  components: {
    CopyStakeGamificationCenterWheelOfFortuneCampaignsTable: (): any =>
      import(
        '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/CampaignsTable/CopyStakeWheelOfFortuneCampaignsTable.vue'
      ),
    CopyStakeWheelOfFortuneCampaignDialog: (): any =>
      import(
        '@/views/CopyStake/CopyStakeGamificationCenter/WheelOfFortune/ManageCampaignDialog/CopyStakeWheelOfFortuneCampaignDialog.vue'
      ),
    ConfirmationDialog: (): any =>
      import('@/components/ConfirmationDialog.vue')
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      needToRefetchTableData: false,
      isWheelOfFortuneFeatureFlagEnabledProcessing: false,
      dialogs: {
        confirmationDialog: false,
        copyStakeWheelOfFortuneCampaignDialog: false,
        isLoading: false,
        campaignId: null,
        isCopy: false,
        confirmationData: null
      }
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', [
      'isFeaturesLoading',
      'isEnabledFeatureFlag',
      'featureFlagIdByName'
    ]),
    wheelOfFortuneFeatureFlagId(): number | null {
      return this.featureFlagIdByName(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_WHEEL_OF_FORTUNE
      );
    },
    isWheelOfFortuneFeatureFlagEnabled(): boolean {
      return this.isEnabledFeatureFlag(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_WHEEL_OF_FORTUNE
      );
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorCopyStakeFeatureFlags']),
    async handleClickIsWheelOfFortuneFeatureFlagEnabledSwitch(): Promise<void> {
      if (
        this.isWheelOfFortuneFeatureFlagEnabledProcessing ||
        !this.wheelOfFortuneFeatureFlagId
      ) {
        return;
      }

      try {
        this.isWheelOfFortuneFeatureFlagEnabledProcessing = true;

        await updateStateFeatureFlag(
          this.wheelOfFortuneFeatureFlagId,
          !this.isWheelOfFortuneFeatureFlagEnabled
        );
        await this.getOperatorCopyStakeFeatureFlags();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isWheelOfFortuneFeatureFlagEnabledProcessing = false;
      }
    },
    async changeStatusWheelOfFortuneCampaign(
      campaign: IWheelOfFortuneCampaign,
      newStatus: EWheelOfFortuneCampaignStatusType,
      isRestore: boolean = false
    ): Promise<void> {
      const statusesConfig = {
        [EWheelOfFortuneCampaignStatusType.ACTIVE]: {
          toastText: `Campaign "${campaign.name}" was successfully activated`
        },
        [EWheelOfFortuneCampaignStatusType.PENDING]: {
          toastText: `Campaign "${campaign.name}" was successfully ${
            isRestore ? 'restored' : 'deactivated'
          }`
        },
        [EWheelOfFortuneCampaignStatusType.DELETED]: {
          toastText: `Campaign "${campaign.name}" was successfully removed`
        }
      };

      try {
        this.dialogs.isLoading = true;

        await updateCampaignStatus(campaign.id, newStatus);

        this.handlerCloseConfirmationDialog();
        this.$toast.success(statusesConfig[newStatus].toastText);

        this.updateCampaignsDataTable();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.dialogs.isLoading = false;
      }
    },
    openCopyStakeWheelOfFortuneCampaignDialog(
      campaign: IWheelOfFortuneCampaignDetails | null,
      isCopy: boolean = false
    ): void {
      this.dialogs.campaignId = campaign?.id || null;
      this.dialogs.copyStakeWheelOfFortuneCampaignDialog = true;
      this.dialogs.isCopy = isCopy;
    },
    openActivateCampaignConfirmationDialog(
      campaign: IWheelOfFortuneCampaignDetails
    ): void {
      this.dialogs.confirmationData = {
        disclaimer: `Are you sure you want to activate this campaign?`,
        header: 'Activate Campaign',
        okText: 'Activate',
        handler: () =>
          this.changeStatusWheelOfFortuneCampaign(
            campaign,
            EWheelOfFortuneCampaignStatusType.ACTIVE
          )
      };
      this.dialogs.confirmationDialog = true;
    },
    openDeactivateCampaignConfirmationDialog(
      campaign: IWheelOfFortuneCampaignDetails
    ): void {
      this.dialogs.confirmationData = {
        disclaimer: `Are you sure you want to deactivate this campaign?`,
        header: 'Deactivate Campaign',
        okText: 'Deactivate',
        handler: () =>
          this.changeStatusWheelOfFortuneCampaign(
            campaign,
            EWheelOfFortuneCampaignStatusType.PENDING
          )
      };
      this.dialogs.confirmationDialog = true;
    },
    openRemoveCampaignConfirmationDialog(
      campaign: IWheelOfFortuneCampaignDetails
    ): void {
      this.dialogs.confirmationData = {
        disclaimer: `Are you sure you want to remove this campaign?`,
        header: 'Remove Campaign',
        okText: 'Remove',
        handler: () =>
          this.changeStatusWheelOfFortuneCampaign(
            campaign,
            EWheelOfFortuneCampaignStatusType.DELETED
          )
      };
      this.dialogs.confirmationDialog = true;
    },
    openRestoreCampaignConfirmationDialog(
      campaign: IWheelOfFortuneCampaignDetails
    ): void {
      this.dialogs.confirmationData = {
        disclaimer: `Are you sure you want to restore this campaign?`,
        header: 'Restore Campaign',
        okText: 'Restore',
        handler: () =>
          this.changeStatusWheelOfFortuneCampaign(
            campaign,
            EWheelOfFortuneCampaignStatusType.PENDING,
            true
          )
      };
      this.dialogs.confirmationDialog = true;
    },
    updateCampaignsDataTable(): void {
      this.needToRefetchTableData = true;
    },
    handlerClickAddCampaignBtn(): void {
      this.openCopyStakeWheelOfFortuneCampaignDialog();
    },
    handlerSubmitCopyStakeWheelOfFortuneCampaignDialog(): void {
      this.updateCampaignsDataTable();
    },
    handlerCloseCopyStakeWheelOfFortuneCampaignDialog(): void {
      this.dialogs.copyStakeWheelOfFortuneCampaignDialog = false;
      this.dialogs.campaignId = null;
      this.dialogs.isCopy = false;
    },
    handlerCloseConfirmationDialog(): void {
      this.dialogs.confirmationDialog = false;
      this.dialogs.confirmationData = null;
    },
    handlerEditCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openCopyStakeWheelOfFortuneCampaignDialog(campaign);
    },
    handlerCopyCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openCopyStakeWheelOfFortuneCampaignDialog(campaign, true);
    },
    handlerActivateCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openActivateCampaignConfirmationDialog(campaign);
    },
    handlerDeactivateCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openDeactivateCampaignConfirmationDialog(campaign);
    },
    handlerRemoveCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openRemoveCampaignConfirmationDialog(campaign);
    },
    handlerRestoreCampaignEvent(campaign: IWheelOfFortuneCampaign): void {
      this.openRestoreCampaignConfirmationDialog(campaign);
    }
  }
};
